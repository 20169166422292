@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;

  background-color: #761773;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%237b297e' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23803a8a' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23844c95' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23895da1' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%238E6FAC' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 2em;
}

.App h1 {
  font-size: 1.5rem;
  color: white;
  margin-top: 3em;
  padding: 1em 2em;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6em;
  background-color: #333;
  color: white;
  padding: 1em 5em;

  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

#break-label,
#session-label {
  text-align: center;
  font-size: 1.4rem;
}

.break {
  display: flex;
  gap: 1em;
}

.break p,
.break button {
  font-size: 1.4rem;
}
.break button,
#timer-label button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

#timer-label {
  border: 1px solid black;
  box-shadow: 1px 1px 5px black, 1px 1px 5px red;

  padding: 2em 6em;
  font-size: 1.5rem;
  margin-bottom: 5em;
}

#timer-label {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  text-align: center;
}

#timer-label button {
  font-size: 1.4rem;
}

@media (max-width: 572px) {
  .container {
    flex-direction: column;
    gap: 1em;
    padding: 0.5em 5em;
  }

  #break-label,
  #session-label,
  #timer-label {
    text-align: center;
    font-size: 1rem;
  }

  #timer-label {
    border: 1px solid black;
    padding: 2em 7em;
    margin-bottom: 5em;
  }
}
